import request from "@/plugins/axios";

class CompanyChargePlanOperationLogApi {
  listURL = "/api/company/platform/charge-plan-operation-log/list";
  listEffectKey = `post${this.listURL}`;
  list = async (data) => {
    const result = await request({
      url: this.listURL,
      data: data,
      method: "post",
    });
    return result;
  };
}

export const companyChargePlanOperationLogApi = new CompanyChargePlanOperationLogApi();
