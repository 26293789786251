<template>
  <div class="app-body _coupon-detail">
    <div class="cg-flex cg-justify-between cg-items-center">
      <bread-crumb></bread-crumb>
      <el-button type="danger" @click="onClickDelete">删除</el-button>
    </div>
    <el-card class="el-main !cg-p-0" :body-style="{ padding: 0 }">
      <el-tabs v-model="selectedIndex" class="cg-pb-80">
        <el-tab-pane label="定价信息" name="0">
          <div class="cg-w-3/5">
            <fee-rule-detail :price-detail="priceDetail" />
          </div>
        </el-tab-pane>
        <el-tab-pane :label="`站点信息（${stationCount}）`" name="1">
          <station-table v-if="selectedIndex === '1'" :total.sync="stationCount" />
        </el-tab-pane>
        <el-tab-pane label="操作记录" name="2">
          <record-table v-if="selectedIndex === '2'" />
        </el-tab-pane>
      </el-tabs>
      <div class="cg-absolute cg-bottom-20 cg-bg-white cg-py-12 cg-px-16">
        <el-button v-if="selectedIndex === '0'" type="primary" @click="onClickEdit">修改</el-button>
        <el-button @click="onClickBack">返回</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import FeeRuleDetail from "@/components-new/FeeRuleDetail/index.vue";
import { companyChargePlanApi } from "@/services/apis/company/charge-plan";

import RecordTable from "./components/RecordTable.vue";
import StationTable from "./components/StationTable.vue";

export default {
  components: { StationTable, RecordTable, FeeRuleDetail },
  data() {
    return {
      selectedIndex: 0,
      tabList: [{}],
      priceDetail: {},
      stationCount: 0,
    };
  },
  computed: {
    isPowerFee() {
      return this.priceDetail.isPowerFee;
    },
  },
  mounted() {
    this.getPriceDetail();
  },
  methods: {
    ...mapActions(["delView"]),
    async getPriceDetail() {
      this.priceDetail = await companyChargePlanApi.detail({
        id: this.$route.query.id,
      });
      this.stationCount = this.priceDetail.usingStationCount || 0;
    },
    onClickBack() {
      this.$router.back();
    },
    onClickEdit() {
      this.$router.push({
        path: "price-edit",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    async onClickDelete() {
      let content = "是否确定删除该定价？";
      if (this.stationCount) {
        content = "该定价存在使用中的站点，请解除站点的定价后再删除。";
      }
      await this.$confirmModal(content, "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        asyncFn: !this.stationCount
          ? async () => await companyChargePlanApi.delete({ id: this.$route.query.id })
          : undefined,
      });
      if (!this.stationCount) {
        this.$message.success("删除成功");
        this.delView(this.$route);
        this.$router.back();
      }
    },
  },
};
</script>

<style scoped>
.el-main /deep/ .el-tabs__nav-wrap {
  padding-left: 20px;
}
.el-main /deep/ .el-tabs__header {
  position: absolute;
  z-index: 1;
  width: 100%;
  background: white;
}
.el-main /deep/ .el-tabs__content {
  padding: 56px 20px 15px;
}
</style>
