<template>
  <le-pagview
    :page-param="pageParam"
    :fresh-cache-ctrl="freshCacheCtrl"
    :total.sync="innerTotal"
    @setData="setTableData"
  >
    <el-table
      v-sticky="{ top: 0, parent: '.el-card__body' }"
      :data="tableData"
      :highlight-current-row="true"
    >
      <el-table-column prop="__number__" label="序号"></el-table-column>
      <el-table-column prop="name" label="站点名称">
        <template slot-scope="{ row }">
          <el-link class="!cg-text-primary" @click="onClickStationName(row.stationId)">
            {{ row.name }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="详细地址"></el-table-column>
      <el-table-column prop="bindUserName" label="绑定人员"></el-table-column>
      <el-table-column prop="phone" label="手机号码"></el-table-column>
      <el-table-column prop="bindTime" label="绑定时间"></el-table-column>
    </el-table>
    <template slot="footerLabel">
      <div class="a-flex-rcfe">
        <span class="a-c-666 a-fs-12">筛选出</span>
        <span class="a-fs-16 a-fw-b a-plr-10">{{ total }}</span>
        <span class="a-c-666 a-fs-12">个站点</span>
      </div>
    </template>
  </le-pagview>
</template>

<script>
import { companyChargePlanApi } from "@/services/apis/company/charge-plan";

export default {
  name: "StationTable",
  props: {
    total: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      tableData: [],
      freshCacheCtrl: 1,
      pageParam: {
        url: companyChargePlanApi.bindStationsURL,
        method: "post",
        params: {
          chargePlanId: this.$route.query.id,
        },
        freshCtrl: 1,
      },
    };
  },
  computed: {
    innerTotal: {
      get() {
        return this.total;
      },
      set(val) {
        this.$emit("update:total", val);
      },
    },
  },
  methods: {
    //获取车辆列表
    setTableData(data) {
      this.tableData = data.map((v) => ({
        ...v,
      }));
    },

    onClickStationName(id) {
      this.$router.push({
        path: "/station/station-detail",
        query: { stationId: id },
      });
    },
  },
};
</script>

<style scoped></style>
