<template>
  <le-pagview
    :page-param="pageParam"
    :fresh-cache-ctrl="freshCacheCtrl"
    :total.sync="total"
    @setData="setTableData"
  >
    <el-table
      v-sticky="{ top: 0, parent: '.el-card__body' }"
      :data="tableData"
      :highlight-current-row="true"
    >
      <el-table-column prop="__number__" label="序号"></el-table-column>
      <el-table-column prop="sourceTypeLabel" label="终端"></el-table-column>
      <el-table-column prop="operationTypeLabel" label="类型"></el-table-column>
      <el-table-column prop="operatorName" label="操作人"></el-table-column>
      <el-table-column prop="operatorPhone" label="手机号码"></el-table-column>
      <el-table-column prop="operationTime" label="操作时间"></el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="{ row }">
          <div class="a-flex-rcc">
            <el-link type="primary" @click.prevent="onClickDetail(row)">定价详情</el-link>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <template slot="footerLabel">
      <div class="a-flex-rcfe">
        <span class="a-c-666 a-fs-12">共</span>
        <span class="a-fs-16 a-fw-b a-plr-10">{{ total }}</span>
        <span class="a-c-666 a-fs-12">条</span>
      </div>
    </template>
    <fee-rule-modal
      :visible.sync="showPriceModal"
      :loading="fetchingDetail"
      :price-detail="priceDetail"
    />
  </le-pagview>
</template>

<script>
import { chargePlanOperationLogTransform } from "@charge/common";
import { mapState } from "vuex";

import FeeRuleModal from "@/components-new/FeeRuleModal/index";
import { companyChargePlanOperationLogApi } from "@/services/apis/company/charge-plan-operation-log";

export default {
  name: "RecordTable",

  components: { FeeRuleModal },

  data() {
    return {
      showPriceModal: false,
      priceDetail: {},
      tableData: [],
      freshCacheCtrl: 1,
      pageParam: {
        url: companyChargePlanOperationLogApi.listURL,
        method: "post",
        params: {
          chargePlanId: this.$route.query.id,
        },
        freshCtrl: 1,
      },
      total: 0,
    };
  },

  computed: {
    ...mapState("loading", ["effects"]),

    fetchingDetail() {
      return !!this.effects[companyChargePlanOperationLogApi.listEffectKey];
    },
  },
  methods: {
    //获取车辆列表
    setTableData(data) {
      this.tableData = chargePlanOperationLogTransform.convertList(data);
    },

    onClickStationName(id) {
      this.$router.push({ path: "/station/station-detail", query: { stationId: id } });
    },

    async onClickDetail(row) {
      this.showPriceModal = true;
      this.priceDetail = row.chargePlan;
    },
  },
};
</script>

<style scoped></style>
